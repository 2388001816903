import React from 'react';
import { Box } from '@mui/material';

const Line = ({
  left,
  right,
  isTotal
}: {
  left: React.ReactNode;
  right: React.ReactNode;
  isTotal?: boolean;
}) => {
  return (
    <>
      <Box
        sx={
          isTotal
            ? {
                borderTop: '1px solid #ddd',
                paddingTop: '10px',
                marginTop: '14px',
                fontWeight: 700
              }
            : null
        }
      >
        <span
          style={{
            textAlign: 'left',
            minWidth: '70%',
            display: 'inline-block'
          }}
        >
          {left}
        </span>
        <span
          style={{
            textAlign: 'right',
            minWidth: '30%',
            display: 'inline-block'
          }}
        >
          {right}
        </span>
      </Box>
    </>
  );
};

export default Line;
