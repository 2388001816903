/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef } from 'react';
import {
  Box,
  Link,
  Typography,
  List,
  Radio,
  ListItem,
  ListItemText,
  Checkbox,
  Card,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Select,
  InputLabel,
  Button,
  MenuItem,
  AccordionActions
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { format, addWeeks, addDays } from 'date-fns';
import MonetizingLink from '@components/MonetizingLink';
import useLocalStorage from '@hooks/useLocalStorage';
import {
  AccordionSummaryInnerCard,
  AccordionWrapper,
  AccordionDetails
} from '@components/Accordion';
import { formatPrice, getProductLink } from '@utils/index';
import { DealPostType } from '@types';
import config from '@configFile';
import colors from '@utils/colors';

const ssMinAmount = (num: number) => {
  switch (num) {
    case 10:
      return 0;
    case 5:
      return 5;
    case 15:
    default:
      return 5;
  }
};

interface SubscribeSavePillProps {
  regularDiscount: number;
  extraDiscount: number;
}

const SubscribeSavePill: React.FC<SubscribeSavePillProps> = ({
  regularDiscount,
  extraDiscount
}) => {
  return (
    <Box
      sx={{
        display: 'inline-flex',
        borderRadius: '30px',
        overflow: 'hidden',
        border: '1px solid #ddd',
        fontFamily: `Arial, sans-serif`,
        fontSize: '14px',
        fontWeight: 700
      }}
      aria-hidden="true"
    >
      {/* Left section: Regular discount */}
      <Box
        sx={{
          backgroundColor: '#ddd',
          padding: '4px 8px',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Typography
          sx={{
            color: '#555'
          }}
        >
          {regularDiscount}%
        </Typography>
      </Box>

      {/* Right section: Extra discount */}
      <Box
        sx={{
          backgroundColor: '#ffa723',
          padding: '4px 8px',
          // color: '#B12704',
          borderLeft: '1px solid #ddd',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Typography>{extraDiscount}%</Typography>
      </Box>
    </Box>
  );
};

const CouponBadge = ({ couponAmount }: { couponAmount: string }) => {
  return (
    <Box
      sx={{
        maxWidth: '100%',
        display: 'block',
        margin: '0 auto 16px auto',
        textAlign: 'center'
      }}
      aria-hidden="true"
    >
      <Box
        sx={{
          position: 'relative',
          display: 'inline',
          backgroundColor: '#ff9900',
          borderRadius: '3px 0 0 3px',
          padding: '4px 5px 4px 6px',
          fontWeight: 'bold',
          color: 'black',
          fontSize: '14px',
          lineHeight: '1.05',
          textAlign: 'center',
          whiteSpace: 'nowrap',
          minWidth: '54px'
        }}
      >
        <Typography
          variant="body2"
          sx={{
            font: `Arial, sans-serif`,
            fontWeight: 700,
            display: 'inline'
          }}
        >
          Coupon:
        </Typography>
      </Box>
      <Checkbox checked={false} tabIndex={-1} />
      <Typography
        variant="body1"
        sx={{
          color: '#007600',
          display: 'inline'
        }}
      >
        {`Save an extra ${couponAmount} on your first Subscribe and Save order.`}
      </Typography>
    </Box>
  );
};

const RedeemStepsList = ({ deal }: { deal: DealPostType | null }) => {
  const [defaultExpanded, setDefaultExpanded] = useLocalStorage(
    'how-to-save-accordion-expanded',
    'no'
  );
  const accordionRef = useRef<HTMLDivElement | null>(null);
  if (!deal) {
    return null;
  }

  if (
    !deal?.ss ||
    !deal?.maxSs ||
    (!deal?.couponPercentage && !deal?.couponFixed)
  ) {
    return null;
  }

  const tag = config.AFFILIATE_TAGS.HOW_TO_GET_DEAL;
  const productLink = getProductLink(deal.ASIN, tag);

  const couponAmount = deal.couponFixed
    ? `${formatPrice(deal.couponFixed)}`
    : `${deal.couponPercentage}%`;

  const coupontAmountOff = `${couponAmount} off`;

  const cancelUrl = `https://www.amazon.com/gp/subscribe-and-save/manager/viewsubscriptions?ref_=ya_d_l_subscribe_save&tag=${tag}`;

  const renderSubscribeSaveOption = () => {
    return (
      <>
        <Box
          aria-hidden="true"
          sx={{
            border: '1px #d5d9d9 solid',
            padding: '10px',
            borderRadius: '5px',
            maxWidth: '200px',
            minWidth: '155px',
            position: 'relative',
            display: 'block',
            margin: '0 auto 16px auto'
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              right: '0px',
              backgroundColor: 'white',
              padding: '0 5px',
              color: 'black',
              fontFamily: 'Arial',
              fontSize: '12px',
              fontWeight: 'bold'
            }}
          >
            <Radio checked tabIndex={-1} />
          </Box>
          <Typography
            variant="body1"
            gutterBottom
            align="left"
            sx={{
              color: 'black',
              fontWeight: 'bold',
              fontFamily: 'Arial',
              fontSize: '13px'
            }}
          >
            Subscribe & Save:
          </Typography>
          <SubscribeSavePill
            regularDiscount={ssMinAmount(deal?.maxSs)}
            extraDiscount={deal.maxSs}
          />
        </Box>
      </>
    );
  };

  const renderFirstDeliveryOption = () => {
    const today = new Date();
    const twoWeeksLater = addWeeks(today, 2);
    const formattedDate = format(twoWeeksLater, 'MMM d'); // Formats to 'Oct 22'
    const formattedDateLong = format(twoWeeksLater, 'EEEE, MMMM d'); // Formats to 'October 22'
    const tomorrow = addDays(today, 1);
    const formattedTomorrow = format(tomorrow, 'EEEE, MMMM d'); // Formats to 'October 22'
    return (
      <>
        <Box
          sx={{
            marginBottom: '16px'
          }}
          aria-hidden="true"
        >
          <Typography
            variant="body1"
            sx={{
              color: 'black',
              fontWeight: 'bold',
              fontFamily: 'Arial',
              fontSize: '13px'
            }}
          >
            {`First delivery on ${formattedDate}`}
            <Link
              href="#"
              sx={{
                marginLeft: '8px',
                color: '#007185'
              }}
              tabIndex={-1}
            >
              Change
            </Link>
          </Typography>
          <Box
            sx={{
              marginTop: '8px'
            }}
          >
            <Card
              sx={{
                p: 2
              }}
            >
              <Box>
                <FormControl tabIndex={-1}>
                  <RadioGroup
                    tabIndex={-1}
                    defaultValue="box"
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value="box"
                      control={<Radio tabIndex={-1} />}
                      sx={{
                        marginBottom: '12px',
                        fontWeight: 'bold'
                      }}
                      label={`Get it with your next Subscribe & Save delivery ${formattedDateLong}, with ${deal?.maxSs}% savings`}
                    />
                    <FormControlLabel
                      value="now"
                      control={<Radio tabIndex={-1} />}
                      sx={{
                        // bold label
                        fontWeight: 'bold'
                      }}
                      label={`Get it sooner, FREE delivery Tomorrow, ${formattedTomorrow},
            with ${ssMinAmount(deal?.maxSs)}% savings`}
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Card>
          </Box>
        </Box>
      </>
    );
  };

  const renderDeliveryFrequencyOption = () => {
    return (
      <>
        <Box
          sx={{
            margin: '0 auto 16px auto',
            display: 'table'
          }}
          aria-hidden="true"
        >
          <FormControl>
            <InputLabel id="deliver-every-label">Deliver every</InputLabel>
            <Select
              labelId="deliver-every-label"
              id="deliver-every"
              value={6}
              label="Deliver every"
              onChange={() => {}}
              disabled
              size="small"
              tabIndex={-1}
            >
              <MenuItem tabIndex={-1} value={6}>
                6 Months
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </>
    );
  };

  const renderAmazonSetUpNowButton = () => {
    return (
      <Box
        sx={{
          margin: '0 auto 16px auto'
        }}
        aria-hidden="true"
      >
        <Button
          variant="contained"
          color="primary"
          tabIndex={-1}
          sx={{
            display: 'block',
            margin: '0 auto',
            marginBottom: '16px',
            textTransform: 'none',
            backgroundColor: '#ffd814',
            color: 'black',
            fontWeight: 'normal',
            borderRadius: '30px',
            // hover
            '&:hover': {
              backgroundColor: '#ffd814',
              color: 'black'
            }
          }}
        >
          Set Up Now
        </Button>
      </Box>
    );
  };

  return (
    <Box
      ref={accordionRef}
      sx={{
        marginTop: '8px'
      }}
    >
      <AccordionWrapper
        expanded={defaultExpanded === 'yes'}
        onChange={(_event, expanded) =>
          setDefaultExpanded(expanded ? 'yes' : 'no')
        }
      >
        <AccordionSummaryInnerCard
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          How to Use Subscribe & Save with a Coupon to Get the Lowest Price
        </AccordionSummaryInnerCard>
        <AccordionDetails
          sx={
            {
              // padding: '20px'
            }
          }
        >
          <List
            component="ol"
            sx={{ listStyleType: 'none', paddingInlineStart: '0px' }}
          >
            <ListItem sx={{ display: 'list-item' }}>
              {deal?.image100Url &&
              deal?.image100Width &&
              deal?.image100Height ? (
                <MonetizingLink
                  clickType="product-page"
                  tag={tag}
                  href={productLink}
                  underline="hover"
                  target="_blank"
                  sx={{
                    color: colors.jdbPurple,
                    fontWeight: 'bold'
                  }}
                >
                  <Box
                    component="img"
                    src={deal?.image100Url}
                    alt="Go to product page"
                    sx={{
                      maxWidth: `${deal?.image100Width}px`,
                      margin: '0px auto 10px auto',
                      display: 'block'
                    }}
                    width={deal?.image100Width}
                    height={deal?.image100Height}
                  />
                </MonetizingLink>
              ) : null}
              <ListItemText
                primary={
                  <MonetizingLink
                    clickType="product-page"
                    tag={tag}
                    href={productLink}
                    underline="hover"
                    target="_blank"
                    sx={{
                      color: colors.jdbPurple
                    }}
                  >
                    1. Click <strong>here</strong> to go to the product page on
                    Amazon
                  </MonetizingLink>
                }
              />
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              {renderSubscribeSaveOption()}
              <ListItemText
                primary={
                  <Typography>
                    2. Click the &quot;Subscribe & Save&quot; purchase option on
                    the{' '}
                    <MonetizingLink
                      clickType="product-page"
                      tag={tag}
                      href={productLink}
                      underline="hover"
                      target="_blank"
                      sx={{
                        color: colors.jdbPurple,
                        fontWeight: 'bold'
                      }}
                    >
                      <strong>product page</strong> on Amazon
                    </MonetizingLink>
                  </Typography>
                }
                secondary={`The Amazon UI may default to "One-time purchase." Click "Subscribe & Save" to see the ${coupontAmountOff} coupon that is eligible for this purchase option.`}
              />
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <CouponBadge couponAmount={couponAmount} />
              <ListItemText
                primary={
                  <Typography>
                    3. Click on &quot;Save an extra {couponAmount} on your first
                    Subscribe and Save order&quot; on the{' '}
                    <MonetizingLink
                      clickType="product-page"
                      tag={tag}
                      href={productLink}
                      underline="hover"
                      target="_blank"
                      sx={{
                        color: colors.jdbPurple,
                        fontWeight: 'bold'
                      }}
                    >
                      <strong>product page</strong> on Amazon
                    </MonetizingLink>
                  </Typography>
                }
                secondary="This coupon will apply to your first Subscribe & Save order, which you can cancel at any time."
              />
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              {renderFirstDeliveryOption()}
              <ListItemText
                primary={`4. Make sure first delivery ships with your next "Subscribe & Save" order`}
                secondary={`Sometimes you want a smaller discount for immediate shipping, but you can also opt to wait for your account's monthly Subscribe & Save date. Click the "Change" link in the Subscribe & Save purchase option, make sure "Get it with your next Subscribe & Save delivery" is selected to make sure your box will get the maximum subscribe & save discount.`}
              />
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              {renderDeliveryFrequencyOption()}
              <ListItemText
                primary="5. Set delivery frequency to 6 months"
                secondary={
                  <Typography>
                    {`Give yourself ample amount of time to decide if you want to keep the subscription or`}{' '}
                    <MonetizingLink
                      clickType="product-page"
                      tag={tag}
                      href={cancelUrl}
                      underline="hover"
                      target="_blank"
                      sx={{
                        color: colors.jdbPurple,
                        fontWeight: 'bold'
                      }}
                    >
                      to cancel via the subscribe & save page{' '}
                      <strong>here</strong>.
                    </MonetizingLink>
                  </Typography>
                }
              />
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              {renderAmazonSetUpNowButton()}
              <ListItemText
                primary={`6. Click "Set Up Now"`}
                secondary={
                  <Typography>
                    {`This will set up your first Subscribe & Save order with the coupon applied. You can cancel the subscription at any time, but make sure to cancel after the first order ships so you aren't charged the full price on future deliveries.`}
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </AccordionDetails>
        <AccordionActions>
          <Button
            onClick={() => {
              setDefaultExpanded('no');
            }}
          >
            Close
          </Button>
        </AccordionActions>
      </AccordionWrapper>
    </Box>
  );
};

export default RedeemStepsList;
