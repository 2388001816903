import config from '@configFile';

export const getSEOSchema = ({
  title,
  image,
  description = config.DESCRIPTION
}: {
  title: string;
  image: string[];
  description?: string;
}) => {
  return {
    '@context': 'http://www.schema.org',
    '@type': 'Article',
    name: config.app.title,
    headline: title,
    url: config.SITE_URL,
    sameAs: [config.FACEBOOK_LINK],
    logo: config.LOGO,
    image,
    description,
    datePublished: new Date().toISOString(),
    dateModified: new Date().toISOString(),
    potentialAction: [
      {
        '@type': 'SearchAction',
        target: {
          '@type': 'EntryPoint',
          urlTemplate: `${config.CURRENT_URL}/search?q={search_term_string}`
        },
        'query-input': 'required name=search_term_string'
      }
    ]
  };
};

export const getHelmetMeta = ({
  title = "Round up of Amazon's BEST Daily Deals",
  image,
  imageWidth = '800',
  imageHeight = '275',
  description = '',
  url = 'https://www.jungle.deals/daily',
  publishTime = new Date(new Date().setHours(4, 0, 0)).toISOString(),
  ogPath
}: {
  image?: string;
  imageWidth?: string;
  imageHeight?: string;
  title?: string;
  description?: string;
  url?: string;
  ogPath?: string;
  publishTime?: string;
}) => {
  const desc = `${description.slice(0, 130).trim()}...`;
  const ret = [
    { property: 'og:type', content: 'article' },
    { property: 'og:site_name', content: config.app.title },
    {
      property: 'og:description',
      content: desc || title
    },
    {
      name: 'description',
      property: 'description',
      content: desc || title
    },
    { property: 'og:locale', content: 'en_US' },
    {
      property: 'article:published_time',
      content: publishTime
    },
    {
      property: 'article:modified_time',
      content: publishTime
    }
  ];

  if (ogPath) {
    ret.push({ property: 'og:url', content: ogPath });
  } else if (url) {
    ret.push({ property: 'og:url', content: url });
  }

  if (image) {
    ret.push({ property: 'og:alt', content: title });
    ret.push({ property: 'og:image', content: image });

    if (imageWidth && imageHeight) {
      ret.push({ property: 'og:image:width', content: imageWidth });
      ret.push({ property: 'og:image:height', content: imageHeight });
      ret.push({ property: 'og:width', content: imageWidth });
      ret.push({ property: 'og:height', content: imageHeight });
    }
  }

  return ret;
};
