import React, { memo } from 'react';
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import config from '@configFile';
import { DealPostType } from '@types';
import MonetizingLink from '@components/MonetizingLink';
import { getProductLink, parseTitle, getPostPrice } from '@utils/index';
import HowToGetDealText from '@components/HowToGetDealText';
import dealPostOptions from '@utils/dealPostOptions';
import { standardTextStyle, standardLinkStyle } from '@utils/styles';

interface DealPostFeedCardProps {
  deal: DealPostType;
  tag?: string;
}

function hashASIN(asin: string) {
  let hash = 0;
  for (let i = 0; i < asin.length; i++) {
    const char = asin.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + char;
    // eslint-disable-next-line no-bitwise
    hash |= 0; // Convert to 32bit integer
  }
  return Math.abs(hash);
}

function getPhraseForASIN(asin: string) {
  const index = hashASIN(asin) % dealPostOptions.length;
  return dealPostOptions[index];
}

const DealPostSummary = ({
  deal,
  tag = config.AFFILIATE_TAGS.FEED
}: DealPostFeedCardProps) => {
  const { title, isExpired } = deal;

  const priceDisplayStyle = {
    ...standardTextStyle,
    fontWeight: 700
  };

  const randomDealPostOption = getPhraseForASIN(deal.ASIN);

  const renderPostPrice = () => {
    if (isExpired) {
      return null;
    }
    return (
      <Typography sx={priceDisplayStyle} display="inline">
        {getPostPrice(deal, true)}
      </Typography>
    );
  };

  return (
    <Box marginTop="8px">
      <Typography variant="body1" sx={standardTextStyle} component="span">
        {isExpired
          ? "This deal is unavailable now for the price we think it should be considered a deal at but here's the information we have for Amazon's"
          : randomDealPostOption}

        <Typography
          display="inline"
          component="span"
          margin="0 4px"
          variant="body1"
        >
          <MonetizingLink
            href={getProductLink(deal.ASIN, tag, deal?.sLink || '')}
            target="_blank"
            underline="hover"
            sx={{
              ...standardLinkStyle,
              fontWeight: 700
            }}
            item="deal-post-feed-card-summary"
            clickType={getProductLink(deal.ASIN, tag, deal?.sLink || '')}
            tag={tag}
          >
            {_.trimEnd(parseTitle(title), isExpired ? '.' : ``)}
            {isExpired ? '.' : ``}
          </MonetizingLink>
        </Typography>
        {isExpired ? '' : `for`}
        {renderPostPrice()}
      </Typography>
      <HowToGetDealText deal={deal} />
    </Box>
  );
};

export default memo(DealPostSummary);
